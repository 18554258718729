
@import "https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap";
  .message-box-session {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: 50px;
    color: #FFF;
    font-family: "Red Hat Display";
    font-weight: 300;
  }
  .message-box-session h3 {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 40px;
  }
  .message-box-session-1 {
    height: 200px;
    width: 380px;
    position: absolute;
    margin-top: 300px;
    margin-left: 50px;
    color: #FFF;
    font-family: "Red Hat Display";
    font-weight: 300;
  }
  .message-box-session-1 h3 {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 40px;
  }
  .buttons-con .action-link-wrap {
    margin-top: 40px;
  }
  .buttons-con .action-link-wrap a {
    background: #68c950;
    padding: 8px 25px;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px
  }
  .buttons-con .action-link-wrap a:hover {
    background: #5A5C6C;
    color: #fff;
  }
  
  #Polygon-1 , #Polygon-2 , #Polygon-3 , #Polygon-4 , #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }
  #Polygon-2 {
    animation-delay: .2s; 
  }
  #Polygon-3 {
    animation-delay: .4s; 
  }
  #Polygon-4 {
    animation-delay: .6s; 
  }
  #Polygon-5 {
    animation-delay: .8s; 
  }
  
  @keyframes float {
      100% {
      transform: translateY(20px);
    }
  }