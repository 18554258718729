.switch-container {
    display: inline-block;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 0px;
    color: white; 
  }
  
  .switch input[type="checkbox"] {
    display: none;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 60%;
    transition: 0.4s;
    z-index: 2; 
  }

  .switch .text {
    position: relative;
    margin-left: 30px;
    font-size: 14px;
    display:none;
  }
  
  .switch input[type="checkbox"]:checked + .slider {
    background-color: #8bc34a; 
  }
  
  .switch input[type="checkbox"]:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .switch.read .slider {
    background-color: #8bc34a;
  }
  
  .switch.read input[type="checkbox"]:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .switch.unread .slider {
    background-color: #ff9800;
  }
  
  .switch.unread input[type="checkbox"]:checked + .slider {
    background-color: #ff9800;
  }
  
  .switch.read {
    color: #8bc34a; 
  }
  
  .switch.unread {
    color: #ff9800; 
  }
  

  .table td {
    font-size: 18px;
    font-weight: 400;
}

  .table th {
    font-size: 16px;
    font-weight: 400;
  }

  .rs-table-cell {
    line-height: "21.17px";
    font-weight: 400 !important;
    font-size: 16px;
    color:'#545454';
    background-color: #ffffff !important;
    border-color:#cce7fc !important;
    border-width: 2px !important;
  }
  
/* In your CSS file or inline styles */


