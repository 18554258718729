
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    scroll-behavior: smooth;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;

  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

   /* Removing anychart credit */
   .anychart-credits,
   .highcharts-credits {
    display: none;
 }
 .react-flow__attribution{
  display: none;
 }


